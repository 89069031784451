/* Transcriber.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  background-color: #ffffff;
  color: #333;
  border-bottom: 1px solid #e7e7e7;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header .title {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #007bff;
}

.header .user-icon {
  display: flex;
  align-items: center;
  font-size: 24px;
}

.header .user-icon .clerk-user-button {
  display: flex;
  align-items: center;
}

.container {
  padding-top: 90px; /* Ensure content is not hidden behind the header */
  margin-top: 20px; /* Add top margin */
}

.segment {
  margin-bottom: 20px;
  cursor: pointer;
  position: relative;
  padding: 10px;
  transition: background-color 0.3s;
}

.segment:hover {
  background-color: #f0f0f0;
}

.segment .edit-bubble,
.segment .play-bubble {
  display: none;
  position: absolute;
  top: 5px;
  background-color: #007bff;
  border: none;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
}

.segment .play-bubble {
  left: 10px;
  background-color: #6c757d;
}

.segment .edit-bubble {
  right: 10px;
}

.editing .segment:hover .edit-bubble,
.segment:hover .play-bubble {
  display: flex;
}

.segment-edit {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.segment-edit input {
  margin-bottom: 10px;
}

.segment p {
  margin: 0;
  font-size: 16px;
}

.segment .text-muted {
  font-size: 12px;
}

.audio-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  border-top: 1px solid #e7e7e7;
  padding: 10px;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
}
