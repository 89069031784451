body {
  font-family: Arial, sans-serif;
}

.navbar {
  margin-bottom: 20px;
  height: 100px;
   /* Adjust padding to reduce height */
}

.navbar-brand {
  font-weight: bold;
}

.navbar-nav .nav-link {
  font-size: 1rem;
}

footer {
  background-color: #f8f9fa;
  padding: 20px 0;
}

footer p {
  margin: 0;
}


h1 {
  font-size: 2.5rem;
  font-weight: bold;
}

p {
  font-size: 1.25rem;

}

.btn {
  font-size: 1rem;
  padding: 10px 20px;
}
